<footer class="mx-3" *ngIf="defaultFooter">
  <div class="navbar navbar-bottom navbar-dark bg-dark m-0 rounded">
    <div class="navbar-inner">
      <div class="container">
        <ul class="nav list-unstyled">
          <li>
            <p class="navbar-text p-0 m-0">{{'footer.text' | translate}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<div id="footer" class="fl-panel fl-note fl-bevel-white fl-font-size-80 custom-fixed-bottom" *ngIf="!defaultFooter">
  <hr>
  <footer>
    <div class="navbar-bottom">
      <div class="navbar-inner">
        <div class="container-fluid">
          <ul class="nav list-unstyled">
            <li class="nav-item" role="presentation"><a><span>{{'footer.text' | translate}}</span></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</div>