import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { defaultClientList } from '../helpers/defaultClients';
import { AppService } from '../services/app.service';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  logo: string = '';
  faqUrl: string = '';
  showFbdmsHeader: boolean = false;
  private logoUrlSubscription: Subscription | undefined;
  constructor(
    private appService: AppService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.showFbdmsHeader =
      defaultClientList.includes(localStorage.getItem('clientCode')?.toUpperCase()) ||
      localStorage.getItem('clientCode') == ''
        ? true
        : false;
    this.logoUrlSubscription = this.appService.logoUrlChange.subscribe(
      (value:any) => {
        this.logo = value;
      }
    );
    //this.logo = this.appService.getLogoUrl();
    this.faqUrl = this.appService.getFaqUrl();
  }

  ngOnDestroy(): void {
    this.logoUrlSubscription?.unsubscribe();
  }
}
