import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { ConfigService } from './config.service';
import { CssService } from './css.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  logoUrl: string = '';
  iconUrl: string = '';
  faqUrl: string = '';
  cssUrl: string = '';
  landingImageUrl: any = [];

  logoUrlChange: Subject<string> = new Subject<string>();
  iconUrlChange: Subject<string> = new Subject<string>();
  faqUrlChange: Subject<string> = new Subject<string>();
  cssUrlChange: Subject<string> = new Subject<string>();
  landingImageUrlChange: Subject<any> = new Subject<any>();

  constructor(private config: ConfigService, private cssService: CssService) {
    this.logoUrlChange.subscribe((value:any) => {
      this.logoUrl = value;
    });
    this.iconUrlChange.subscribe((value:any) => {
      this.iconUrl = value;
    });
    this.faqUrlChange.subscribe((value:any) => {
      this.faqUrl = value;
    });
    this.cssUrlChange.subscribe((value:any) => {
      this.cssUrl = value;
    });
    this.landingImageUrlChange.subscribe((value:any) => {
      this.landingImageUrl = value;
    });
    this.config.loadAppConfig().then(() => this.refreshAssets());
    //this.refreshAssets();
  }

  /*getAssets(): any {
    return this.config.assets;
  }*/

  refreshAssets(newAssets?: any) {
    const assets = newAssets ? newAssets : this.config.assets;
    console.log('###### Refreshing assets... ');
    console.log('### Logo ###' + assets.logoUrl);
    console.log('### Icon ###' + assets.iconUrl);
    console.log('### CSS ###' + assets.stylesheetUrl);
    console.log('### Landing image ###'+ assets.landingImageUrl)

    this.logoUrlChange.next(assets.logoUrl);
    this.faqUrlChange.next(assets.faqUrl);
    this.cssUrlChange.next(assets.stylesheetUrl);
    this.iconUrlChange.next(assets.iconUrl);
    this.landingImageUrlChange.next(assets.landingImageUrl);
    this.cssService.updateGlobalCss(this.cssUrl, this.iconUrl);
  }

  getLogoUrl(): string {
    return this.logoUrl;
  }

  getCssUrl(): string {
    return this.cssUrl;
  }

  getFaqUrl(): string {
    return this.faqUrl;
  }

  getIconUrl(): string {
    return this.iconUrl;
  }

  getlandingImageUrl(): any {
    return this.landingImageUrl;
  }
}
