import { Component } from '@angular/core';

@Component({
  selector: 'app-fbdms-header',
  templateUrl: './fbdms-header.component.html',
  styleUrls: ['./fbdms-header.component.scss']
})
export class FbdmsHeaderComponent {

}
