export class ClientHelper {
  private domains: { [key: string]: any } = {
    fxdms: 'FBDMS',
    fbdms: 'FBDMS',
    bsa: 'BANKSA',
    stg: 'STGEORGE',
    bom: 'BANKOFMELBOURNE',
  };

  getClientCode(input?: string): string {
    let client = input?.toLowerCase();
    if (client) {
      if (this.domains[client]) return this.domains[client || ''];
      else return 'FBDMS';
    } else return 'FBDMS';
  }
}
