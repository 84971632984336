import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {}

  public invokeResetPasswordService(payload: any): Observable<any> {
    console.log('in sendSMS service', payload);
    return this.http.post('/upsrv/activation/resetPassword', payload);
  }

  public invokeResetPasswordServiceWithQueryParams(
    params: any,
    url: any
  ): Observable<any> {
    return this.http.get(url, {
      headers: this.headers,
      params: params,
    });
  }

  public validatePassword(payload: any, url: any): Observable<any> {
    return this.http.post(url, payload);
  }

  public resetPassword(payload: any, url: any): Observable<any> {
    return this.http.post(url, payload, {
      headers: this.headers,
    });
  }
}
