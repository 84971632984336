import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { defaultClientList } from '../helpers/defaultClients';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  landingImageUrl: any = [];
  private landingImageUrlSubscription: Subscription | undefined;
  defaultPage: boolean = defaultClientList.includes(localStorage.getItem('clientCode')?.toUpperCase()) || localStorage.getItem('clientCode') == ''
                        ? true
                        : false;

  constructor(private appService: AppService, private router: Router) {}

  ngOnInit(): void {
    this.landingImageUrlSubscription = this.appService.landingImageUrlChange.subscribe(
      (value:any) => {
        this.landingImageUrl = value ? value : [];
      }
    );
  }

  ngOnDestroy(): void {
    this.landingImageUrlSubscription?.unsubscribe();
  }

  signIn = () => {
    const currentUrl = window.location.href;
    console.log('Current url: ', currentUrl);
    window.location.href = this.defaultPage ? currentUrl.replace('/sc', '/scsrv/starthere'): currentUrl.replace('/sc/', `/${localStorage.getItem('tenant')?.toLowerCase()}/vdbe`);
  };

  resetPassword = () => {
    this.router.navigate(['reset']);
  };
}
