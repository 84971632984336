import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { ClientHelper } from './helpers/client-helper';

import { AppComponent } from './app.component';
import { Observable } from 'rxjs';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModuleModule } from './app-routing-module/app-routing-module.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetComponent } from './reset/reset.component';
import { ActivateComponent } from './activate/activate.component';
import { LogService } from './services/log.service';
import { ConfigService } from './services/config.service';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { FbdmsHeaderComponent } from './fbdms-header/fbdms-header.component';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient, config: ConfigService) {
  return new TranslateHttpLoader(http, `${config.translationUrl}`, '.json');
}

export const configFactory = (config: ConfigService): (() => any) => {
  return () => {
    return config
      .getClientCode()
      .then((response: any) => {
        const client = response?.headers.get('Tenant')
          ? response?.headers.get('Tenant')
          : '';
        config.domain = new ClientHelper().getClientCode(client);
        localStorage.setItem('tenant', client);
        localStorage.setItem('clientCode', config.domain);
        config.translationUrl = `./assets/i18n/${config.domain}/`;
        config.loadAppConfig();
      })
      .catch((error: any) => {
        config.domain = new ClientHelper().getClientCode('');
        localStorage.setItem('clientCode', config.domain);
        config.translationUrl = `./assets/i18n/${config.domain}/`;
        config.loadAppConfig();
      });
  };
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ResetPasswordComponent,
    ResetComponent,
    ActivateComponent,
    LandingPageComponent,
    FbdmsHeaderComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModuleModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, ConfigService],
      },
    }),
  ],
  providers: [
    Title,
    LogService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      multi: true,
      deps: [ConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
