import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defaultClientList } from '../helpers/defaultClients';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  clientCode: string = '';
  defaultFooter: boolean = true;

  constructor(
    private translate: TranslateService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.clientCode = localStorage.getItem('clientCode')?.toUpperCase() || '';
    this.defaultFooter =
      defaultClientList.includes(localStorage.getItem('clientCode')?.toUpperCase()) ||
      localStorage.getItem('clientCode') == ''
        ? true
        : false;
  }
}
