import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ClientHelper } from '../helpers/client-helper';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public domain: string = '';
  public assets: any = {};
  public translationUrl: string = '';

  constructor(private http: HttpClient) {}

  getClientCode(): any {
    const baseUrl = '';
    return this.http
      .get(baseUrl, { responseType: 'text', observe: 'response' })
      .toPromise();
  }

  loadAppConfig(): any {
    return this.http
      .get('assets/' + localStorage.getItem('clientCode') + '/config.json')
      .toPromise()
      .then((response) => {
        // do something to reflect into local model
        console.log('#####', response);
        this.assets = response;
        return true;
      });
  }
}
