<!-- not default reset password page // westpac -->
<main class="d-flex flex-nowrap main-content" *ngIf="!defaultPage">
  <div class="container mt-5 mb-5">
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="px-5">
          <h1>
            {{ "title.loginPageHeader1" | translate }}
            <span aria-hidden="true"><br/></span>
            {{ "title.loginPageHeader2" | translate }}
        </h1>          
        </div>
      </div>
      <div class="col-md-6">
        <form class="px-5 mt-2">
          <div class="mx-lg-3 mb-2">
            <!-- Westpac: label Enter a new password-->
            <label for="password" class="form-label">{{'form.label.enter-a-new-password' | translate}}</label>
            <input type="password" class="form-control col-3" id="password" name="password"
              placeholder="{{'form.placeholder.password' | translate}}" [(ngModel)]="password" autofocus>
          </div>
          <div class="mx-lg-3 mb-2">
            <label for="reEnterPassword" class="form-label">{{'form.label.re-enter-password' | translate}}</label>
            <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
              placeholder="{{'form.placeholder.confirm-password' | translate}}" [(ngModel)]="confirmPassword">
          </div>
          <div class="mx-lg-3 mb-2">
            <button type="submit" class="btn btn-primary mb-2 w-100"
              [disabled]="password == '' || password !== confirmPassword"
              (click)="validatePassword()">{{'form.button.resetPassword'
              | translate}}</button>
          </div>
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="invalidToken">
            {{ invalidTokenMessageForReset }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div class="alert alert-danger alert-dismissible fade show" role="alert"
            *ngIf="password !== confirmPassword">
            {{ 'form.validation-msg.match-password' | translate }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showError">
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="showSuccess">
            {{ 'page.reset-password.alert-success.title' | translate }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div id="backToSignIn" *ngIf="showSuccess" style="margin-top: 30px;">
            <p>{{'page.back-to-sign-in.text-1' | translate}}</p>
            <a href="{{homeurl}}" class="btn btn-primary" role="button">{{'form.button.signIn' | translate}}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>

<!-- default reset password page // non-westpac -->
<div class="container mt-2" *ngIf="defaultPage">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active"><a href="{{homeurl}}">
          {{'breadcrumb.home' | translate}}
        </a><span class="divider">/</span></li>
    </ol>
  </nav>
  <div class="row">
    <div class="col-md-6">
      <h3>
        {{'form.button.resetPassword' | translate}}
      </h3>
      <form id="reset-password-form" novalidate>
        <div class="form-group row mb-2">
          <label class="col-form-label col-sm-3" for="password">
            {{'form.label.new-password' | translate}}
          </label>
          <div class="col-sm-5">
            <input id="newPassword" class="form-control col-md-3 mb-2" size="12" type="password" name="newPassword"
              placeholder="{{'form.placeholder.password' | translate}}" [(ngModel)]="password" required autofocus>
          </div>
        </div>
        <div class="form-group row mb-2">
          <label class="col-form-label col-sm-3" for="password">
            {{'form.label.confirm-password' | translate}}
          </label>
          <div class="col-sm-5">
            <input id="confirmPassword" class="form-control col-md-3 mb-2" size="12" type="password"
              name="confirmPassword" placeholder="{{'form.placeholder.confirm-password' | translate}}"
              [(ngModel)]="confirmPassword" required>
          </div>
        </div>

        <div class="form-actions mb-3 ms-3">
          <div class="form-actions">
            <button type="submit" class="btn btn-primary mb-2 me-2"
              [disabled]="password == '' || password !== confirmPassword"
              (click)="validatePassword()">{{'form.button.resetPassword' |
              translate}}</button>
            <button class="btn btn-secondary mb-2" type="reset" id="validation-reset" (click)="cancel()">
              {{'form.button.cancel' |
              translate}}
            </button>
          </div>
        </div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="invalidToken">
          {{ invalidTokenMessageForReset }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="password !== confirmPassword">
          {{ 'form.validation-msg.match-password' | translate }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showError">
          <ul>
            <li *ngFor="let error of errors">
              {{ error }}
            </li>
          </ul>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="showSuccess">
          {{ 'page.reset-password.alert-success.title' | translate }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </form>
    </div>
  </div>
</div>