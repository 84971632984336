<!-- not default activate page // westpac -->
<main class="d-flex flex-nowrap main-content" *ngIf="!defaultPage">
  <div class="container mt-5 mb-5">
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="px-5 mb-2">
          <h1>
              {{ "title.activatePageHeader1" | translate }}
              <span aria-hidden="true"><br /></span>
              {{ "title.activatePageHeader2" | translate }}
          </h1>
        </div>
        <div class="px-5 mb-2">
          <p>{{ "title.activatePageSubheader1" | translate }}</p>
        </div>
        <div class="px-5 mb-2">
          <p>{{ "title.activatePageSubheader2" | translate }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <form class="px-5 mt-2">
          <div class="mx-lg-3 mb-2" *ngIf="!showSuccess && !invalidToken">
            <!-- Westpac: label Enter a new password-->
            <label for="password" class="form-label">{{
              "form.label.create-password" | translate
            }}</label>
            <!-- Westpac: placeholder Password-->
            <input
              type="password"
              class="form-control col-3"
              id="password"
              name="password"
              placeholder="{{ 'form.placeholder.password' | translate }}"
              [(ngModel)]="password"
              autofocus
            />
          </div>
          <div class="mx-lg-3 mb-2" *ngIf="!showSuccess && !invalidToken">
            <!-- Westpac: label Re-enter password-->
            <label for="reEnterPassword" class="form-label">{{
              "form.label.confirm-password" | translate
            }}</label>
            <!-- Westpac: placeholder Confirm password-->
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="{{
                'form.placeholder.confirm-password' | translate
              }}"
              [(ngModel)]="confirmPassword"
            />
          </div>
          <div class="mx-lg-3 mb-2" *ngIf="!showSuccess && !invalidToken">
            <button
              type="submit"
              class="btn btn-primary mb-2 w-100"
              [disabled]="password == '' || password !== confirmPassword"
              (click)="validatePassword()"
            >
              {{ "form.button.activateAccount" | translate }}
            </button>
          </div>
          <div
            class="activate-message alert alert-danger alert-dismissible fade show"
            role="alert"
            *ngIf="invalidToken"
          >
            {{ invalidTokenMessageForActivate }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="activate-message alert alert-danger alert-dismissible fade show"
            role="alert"
            *ngIf="password !== confirmPassword"
          >
            {{ "form.validation-msg.match-password" | translate }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="activate-message alert alert-danger alert-dismissible fade show"
            role="alert"
            *ngIf="showError"
          >
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="alert alert-success alert-dismissible fade show"
            role="alert"
            *ngIf="showSuccess"
          >
            {{ "page.registered.alert-success.title" | translate }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div id="backToSignIn" *ngIf="showSuccess" style="margin-top: 30px">
            <p>{{ "page.back-to-sign-in.text-1" | translate }}</p>
            <a href="{{ homeurl }}" class="btn btn-primary" role="button">{{
              "form.button.signIn" | translate
            }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>

<!-- default activate page // non-westpac -->
<div class="container mt-2" *ngIf="defaultPage">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active">
        <a href="{{ homeurl }}"> {{ "breadcrumb.home" | translate }} </a
        ><span class="divider">/</span>
      </li>
    </ol>
  </nav>

  <!-- Row of columns -->
  <div class="row">
    <div class="col-md-6">
      <h3>{{ "title.activate" | translate }}</h3>
      <form
        *ngIf="!showSuccess && !invalidToken"
        method="POST"
        action="activate.html"
        class="needs-validation"
        id="validation"
        novalidate
      >
        <div class="form-group row mb-2" id="password-control-group">
          <!-- Non-Westpac: label Create password-->
          <label class="col-form-label col-sm-3" for="password">{{
            "form.label.create-password" | translate
          }}</label>
          <!-- Non-Westpac: placeholder Password-->
          <div class="col-sm-5">
            <input
              id="password"
              name="password"
              class="form-control col-md-3 mb-2"
              size="12"
              type="password"
              [(ngModel)]="password"
              placeholder="{{ 'form.placeholder.password' | translate }}"
              required
              data-validation-required-message="You must enter your password"
              data-i18n="[placeholder]form.placeholder.password;[data-validation-required-message]form.validation-msg.require-password"
              autofocus
            />
          </div>
        </div>
        <div class="form-group row mb-2">
          <!-- Non-Westpac: label Confirm password-->
          <label class="col-form-label col-sm-3" for="confirm-password">{{
            "form.label.confirm-create-password" | translate
          }}</label>
          <!-- Non-Westpac: placeholder Confirm password-->
          <div class="col-sm-5">
            <input
              id="confirm-password"
              name="confirm-password"
              class="form-control col-md-3 mb-2"
              size="12"
              type="password"
              [(ngModel)]="confirmPassword"
              placeholder="{{ 'form.label.confirm-password' | translate }}"
              required
            />
          </div>
        </div>
        <div class="form-actions mb-3 ms-3">
          <button
            type="submit"
            class="btn btn-primary mb-2 me-2"
            [disabled]="password == '' || password != confirmPassword"
            (click)="validatePassword()"
          >
            {{ "form.button.activateAccount" | translate }}
          </button>
          <button
            class="btn btn-secondary mb-2"
            type="reset"
            id="validation-reset"
            (click)="cancel()"
          >
            {{ "form.button.cancel" | translate }}
          </button>
        </div>
        <br />

        <div
          class="alert alert-danger alert-dismissible fade show mb-2"
          role="alert"
          *ngIf="password !== confirmPassword"
        >
          {{ "form.validation-msg.match-password" | translate }}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="alert alert-danger alert-dismissible fade show mb-2"
          role="alert"
          *ngIf="showError"
        >
          <ul>
            <li *ngFor="let error of errors">
              {{ error }}
            </li>
          </ul>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </form>
      <!-- Show me on invalid token -->
      <br />
      <div *ngIf="invalidToken">
        <div
          class="alert alert-danger alert-dismissible fade show mb-2"
          role="alert"
        >
          {{ invalidTokenMessageForActivate }}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <!-- Show me on successful activation -->
      <br />
      <div *ngIf="showSuccess">
        <div
          class="alert alert-success alert-dismissible fade show mb-2"
          role="alert"
        >
          <strong>
            {{ "alert.title.success" | translate }}
          </strong>
          {{ "page.activate.alert-success.title" | translate }}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
        <div class="mb-2">
          {{ "page.activate.alert-success.signin" | translate }}
        </div>
        <br />
        <div class="mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 0 24 24"
            width="18px"
            fill="#000000"
          >
            <path
              d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
          </svg>
          <em> {{ "page.activate.alert-success.rem" | translate }} </em><br />{{
            "page.activate.alert-success.text" | translate
          }}
        </div>
        <br />
        <button
          id="btn-signin"
          class="btn btn-sm btn-primary mb-2"
          (click)="signIn()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 0 24 24"
            width="18px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
          </svg>
          {{ "form.button.signIn" | translate }}
        </button>
      </div>
      <!-- END -- Show  me on successful activation -->
    </div>
  </div>
</div>
