<div class="container mb-2">
  <div class="row my-4">
    <div class="col-12">
      <div id="carouselSlidesOnly" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div *ngFor="let img of landingImageUrl;let i=index; first as isFirst">
            <div [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item' ">
              <img src="{{img}}" class="d-block w-100"
                alt="Carousal image">
            </div>
          </div>         
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-4">&nbsp;</div>
    <div class="col-4">&nbsp;</div>
    <div class="col-lg-4 col-sm-12">
      <div class="well">
        <h2>{{'title.signIn' | translate}}</h2>
        <button type="button" class="btn btn-primary text-align-center" (click)="signIn()">
          <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#FFFFFF">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
          </svg> {{'form.button.signIn' | translate}}</button>
        <hr>
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#000000">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
          </svg> If you are having difficulties
          accessing this service, you may attempt to
          <a role="button" aria-label="Reset Password" class="custom-link" (click)="resetPassword()" tabindex="0">{{'page.login.resetPassword' | translate}}</a>
        </p>
      </div>
    </div>
  </div>
</div>