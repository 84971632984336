import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';
import { CssService } from './services/css.service';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from './services/config.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  cssUrl: string = '';
  showFbdmsPage: boolean = false;
  clientName: string = '';
  constructor(
    private appService: AppService,
    private cssService: CssService,
    private translate: TranslateService,
    private router: Router,
    private configService: ConfigService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.configService
      .loadAppConfig()
      .then(() => this.appService.refreshAssets());
    //const assets = this.appService.getAssets();
    //this.cssUrl = assets.stylesheetUrl;
    //this.selectedTheme = this.client;
    //this.cssService.updateGlobalCss(this.cssUrl);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');
    //Fetch and set the client name
    this.translate.get('clientName').subscribe((translatedClientName : string) => {

      if (translatedClientName){
        this.clientName = translatedClientName;
      }
      //Fetch and set the translated title
      this.translate.get('appTitle').subscribe((translatedTitle : string) => {
        if (localStorage.getItem('clientCode')?.toUpperCase() == 'FBDMS' || localStorage.getItem('clientCode') == ''){
          this.titleService.setTitle(translatedTitle);
        } else {
          this.titleService.setTitle(this.clientName + ' | ' + translatedTitle);
        }
      })
    })

    this.showFbdmsPage =
      localStorage.getItem('clientCode')?.toUpperCase() == 'FBDMS' ||
      localStorage.getItem('clientCode') == ''
        ? true
        : false;
  }
}
