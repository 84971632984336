import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { defaultClientList } from '../helpers/defaultClients';
import { ConfigService } from '../services/config.service';
import { LogService } from '../services/log.service';
import { ResetPasswordService } from '../services/reset-password.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {
  resetForm!: FormGroup;
  email: string = '';
  resetLinkSentSuccess: boolean = false;
  resetLinkSentFailed: boolean = false;
  defaultPage: boolean = false;
  homeurl: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private logService: LogService,
    private resetPasswordService: ResetPasswordService,
    private configService: ConfigService
  ) {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.defaultPage =
      defaultClientList.includes(localStorage.getItem('clientCode')?.toUpperCase()) ||
      localStorage.getItem('clientCode') == ''
        ? true
        : false;
    const currentUrl = window.location.href;
    console.log("Current url: ", currentUrl);
    this.homeurl = "https://" + currentUrl.split("/")[2] + "/scsrv/starthere";
  }

  sendResetEmail() {
    let payload = { email: this.email };
    this.logService.debug('Reset password email request: ', payload);
    this.resetPasswordService.invokeResetPasswordService(payload).subscribe(
      (res:any) => {
        this.resetLinkSentSuccess = true;
        this.logService.debug('Reset link sent success: ', res);
      },
      (err:any) => {
        this.resetLinkSentFailed = true;
        this.logService.debug('Reset link sent failed: ', err);
      }
    );
  }
}
