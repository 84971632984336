import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CssService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  updateGlobalCss(cssUrl: string, iconUrl: string) {
    console.log("updating global css");
    let url = cssUrl.split('.')[0];
    const head = document.getElementsByTagName('head')[0];
    console.log(head);
    const links = document.getElementsByTagName('link');
    const noscripts = document.getElementsByTagName('noscript');
    const styles = document.getElementsByTagName('style');
    for (let i = links.length - 1; i >= 0; i--) {
      const link = links[i];
      if (
        link.rel === 'stylesheet' &&
        link.getAttribute('href')?.split('.')[0] !== url
      ) {
        link.parentNode?.removeChild(link);
      } else if (link.rel === 'icon' && link.getAttribute('href') !== iconUrl) {
        link.parentNode?.removeChild(link);
      }
    }
    for (let i = noscripts.length - 1; i >= 0; i--) {
      const innerHTML = noscripts[i].innerHTML;
      if (!innerHTML.includes(url)) {
        noscripts[i].parentNode?.removeChild(noscripts[i]);
      }
    }
    for (let i = styles.length - 1; i >= 0; i--) {
      const innerHTML = styles[i].innerHTML;
      if (!innerHTML.includes(url)) {
        styles[i].parentNode?.removeChild(styles[i]);
      }
    }
    console.log("adding icon link to html");
    const iconLink = this.renderer.createElement('link');
    iconLink.rel = 'icon';
    iconLink.type = 'image/x-icon';
    iconLink.href = iconUrl;
    this.renderer.appendChild(head, iconLink);
  }
}
