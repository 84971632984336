import { Component, OnInit } from '@angular/core';
import { LogService } from '../services/log.service';
import { ResetPasswordService } from '../services/reset-password.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../services/config.service';
import { defaultClientList } from '../helpers/defaultClients';
const UP_SERVICES_CONTEXT_ROOT = '/upsrv/';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  statusCode: any = '';
  invalidToken: boolean = false;
  invalidTokenMessageForReset: string = '';
  password: any = '';
  confirmPassword: any = '';
  showError: boolean = false;
  showSuccess: boolean = false;
  errors: any = [];
  defaultPage: boolean = false;
  homeurl: string = '';

  constructor(
    private logService: LogService,
    private resetPasswordService: ResetPasswordService,
    private translateService: TranslateService,
    private configService: ConfigService
  ) {}
  ngOnInit(): void {
    this.defaultPage =
      defaultClientList.includes(localStorage.getItem('clientCode')?.toUpperCase()) ||
      localStorage.getItem('clientCode') == ''
        ? true
        : false;
    this.validateToken();
    const currentUrl = window.location.href;
    console.log("Current url: ", currentUrl);
    this.homeurl = this.defaultPage ? `https://${currentUrl.split("/")[2]}/scsrv/starthere` : `https://${currentUrl.split('/')[2]}/${localStorage.getItem('tenant')?.toLowerCase()}/vdbe`;
  }

  validateToken = () => {
    this.logService.debug(
      'App.pageController.validateToken() = ' + this.getURLParameter('t')
    );
    this.logService.debug(
      'App.pageController.validateToken() = ' + this.getURLParameter('id')
    );
    var token = this.getURLParameter('t');
    var id = this.getURLParameter('id');
    let params = { userId: id, userToken: token };
    this.resetPasswordService
      .invokeResetPasswordServiceWithQueryParams(
        params,
        UP_SERVICES_CONTEXT_ROOT + 'activation/validateToken'
      )
      .subscribe((res: any) => {
        this.logService.debug(
          'App.pageController.validateToken() - success callback invoked'
        );
        this.statusCode = res.statusCode;
        if (this.statusCode != '70000') {
          this.invalidToken = true;
          this.translateService
            .get(
              'services.activate.resetPassword.' + this.statusCode.toString()
            )
            .subscribe((errorMessage: string) => {
              this.invalidTokenMessageForReset = errorMessage;
            });
        }
        this.logService.debug(
          'App.pageController.validateToken.validateToken() - i18nMessageCode = ' +
            this.statusCode
        );
      });
  };

  validatePassword = () => {
    this.invalidToken = false;
    this.showError = false;
    this.showSuccess = false;
    this.errors = [];
    this.logService.debug(
      'App.pageController.validatePassword() = ' + this.getURLParameter('t')
    );
    this.logService.debug(
      'App.pageController.validatePassword() = ' + this.getURLParameter('id')
    );
    var id = this.getURLParameter('id');
    let payload = {
      password: this.password,
    };
    this.resetPasswordService
      .validatePassword(
        payload,
        UP_SERVICES_CONTEXT_ROOT + 'activation/' + id + '/validatePassword'
      )
      .subscribe(
        (res: any) => {
          this.logService.debug(
            'App.pageController.validatePassword() - success callback invoked'
          );
          var statusCode = res.statusCode;
          if (statusCode == '70000') {
            this.resetPassword();
          } else {
            if (statusCode == '70116') {
              this.showError = true;
              let errorcodes = res.errors;
              for (let i = 0; i < errorcodes.length; i++) {
                this.translateService
                  .get(
                    'services.activate.validatePassword.' +
                      errorcodes[i].toString()
                  )
                  .subscribe((errorMessage: string) => {
                    this.errors.push(errorMessage);
                  });
              }
            }
          }
        },
        (err: any) => {}
      );
  };

  resetPassword = () => {
    this.errors = [];
    var id = this.getURLParameter('id');
    var token = this.getURLParameter('t');
    var request = { token: token, password: this.password };

    this.resetPasswordService
      .resetPassword(
        request,
        UP_SERVICES_CONTEXT_ROOT + 'activation/' + id + '/password'
      )
      .subscribe((res: any) => {
        this.logService.debug(
          'App.pageController.resetPassword() - success callback invoked'
        );
        var statusCode = res.statusCode;

        if (statusCode == '70000') {
          this.showSuccess = true;
        } else {
          if (statusCode == '70116') {
            this.showError = true;
            let errorcodes = res.errors;
            for (let i = 0; i < errorcodes.length; i++) {
              this.translateService
                .get(
                  'services.activate.validatePassword.' +
                    errorcodes[i].toString()
                )
                .subscribe((errorMessage: string) => {
                  this.errors.push(errorMessage);
                });
            }
          } else {
            this.showError = true;
            if (res.errors) {
              this.errors = res.errors;
            } else {
              // the response is only res.statusMessage and res.statusCode
              this.logService.debug(
                'App.pageController.resetPassword() - unaccepted password' + res.statusMessage + ":" + res.statusCode
              );
              this.translateService.get('services.user.changePassword.' + res.statusCode)
                .subscribe((errorMessage: string) => {
                  this.errors.push(errorMessage);
                });
            }

          }
        }
      });
  };

  getURLParameter = (sParam: any): any => {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  };

  cancel = (): any => {
    this.password = '';
    this.confirmPassword = '';
  };

}
