<main class="d-flex flex-nowrap main-content" *ngIf="!defaultPage">
  <div class="container mt-5 mb-5">
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="px-5">
        <h1>
            {{ "title.loginPageHeader1" | translate }}
            <span aria-hidden="true"><br/></span>
            {{ "title.loginPageHeader2" | translate }}
        </h1>
        </div>
      </div>
      <div class="col-md-6">
        <form [formGroup]="resetForm" class="px-5 mt-2">
          <div class="mx-lg-3 mb-2">
            <label for="userId" class="form-label">{{'form.label.userId' | translate}}</label>
            <input type="email" class="form-control col-3" id="userId" name="userId" formControlName="email"
              placeholder="{{'form.placeholder.userID' | translate}}" [(ngModel)]="email" autofocus>
          </div>
          <div *ngIf="resetForm.get('email')?.errors && resetForm.get('email')?.touched" class="mb-2">
            <div class="ms-lg-3" *ngIf="resetForm.get('email')?.errors?.['required']">
              {{'form.validation-msg.require-email' |
              translate}}</div>
            <div class="ms-lg-3" *ngIf="resetForm.get('email')?.errors?.['email']">{{'form.validation-msg.invalid-email'
              |
              translate}}</div>
          </div>
          <div class="mx-lg-3 mb-2">
            <button type="submit" class="btn btn-primary mb-2 w-100"
              [disabled]="resetForm.get('email')?.errors?.['required'] || resetForm.get('email')?.errors?.['email']"
              (click)="sendResetEmail()">{{'form.button.forgotPasswordSubmitButton' |
              translate}}</button>
          </div>
          <div class="activate-message alert alert-success alert-message alert-dismissible fade show" *ngIf="resetLinkSentSuccess">
            <span class="msg">{{'services.activate.resetPassword.70000' | translate}}</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div class="activate-message alert alert-warn alert-message alert-dismissible fade show" *ngIf="resetLinkSentFailed">
            <strong>
              {{'page.reset.alert-warning.title' | translate}}
            </strong> <span class="msg"> {{'page.reset.warning-message.text' | translate}} </span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>
<div class="container mt-2" *ngIf="defaultPage">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active"><a href="{{homeurl}}">
          {{'breadcrumb.home' | translate}}
        </a><span class="divider">/</span></li>
    </ol>
  </nav>
  <div class="row">
    <div class="col-md-6">
      <h3>
        {{'form.button.resetPassword' | translate}}
      </h3>
      <form id="reset-password-form" [formGroup]="resetForm">
        <div class="form-group row mb-2">
          <label class="col-form-label col-sm-3" for="email">
            {{'form.label.email' | translate}}
          </label>
          <div class="col-sm-5">
            <input id="from" class="form-control col-md-3 mb-2" size="12" type="email" [(ngModel)]="email"
              placeholder="{{'form.hint.email' | translate}}" formControlName="email" pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" required autofocus>
            <div *ngIf="resetForm.get('email')?.errors && resetForm.get('email')?.touched" class="mb-2">
              <div *ngIf="resetForm.get('email')?.errors?.['required']">
                {{'form.validation-msg.require-email' |
                translate}}</div>
              <div *ngIf="resetForm.get('email')?.errors?.['email']">{{'form.validation-msg.invalid-email'
                |
                translate}}</div>
            </div>
          </div>
        </div>
        <div class="alert alert-success alert-message alert-dismissible fade show mb-2" *ngIf="resetLinkSentSuccess">
          <strong>
            {{'page.reset.alert-success.title' | translate}}
          </strong> <span class="msg"> {{'services.activate.resetPassword.70000' | translate}} </span>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="alert alert-warn alert-message alert-dismissible fade show mb-2" *ngIf="resetLinkSentFailed">
          <strong>
            {{'page.reset.alert-warning.title' | translate}}
          </strong> <span class="msg"> {{'page.reset.warning-message.text' | translate}} </span>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="form-actions mb-3 ms-3">
          <button type="submit" class="btn btn-primary"
            [disabled]="resetForm.get('email')?.errors?.['required'] || resetForm.get('email')?.errors?.['email']"
            (click)="sendResetEmail()"><i class="icon-white icon-refresh"></i>
            {{'form.button.resetPassword' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>